import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Benefici from '../components/Benefici/Benefici';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function BeneficiPage({ pageContext }) {
  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <Benefici data={data} />
    </MainLayout>
  );
}

export default injectIntl(BeneficiPage);
