/* eslint-disable camelcase */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { map } from 'lodash';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '../Forms/Button/Button';
import MetaTags from '../Header/MetaTags';

function Benefici({ data }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  const {
    foundationHero,
    fiscalBenefits,
    howToSupport,
    supportMethods,
  } = data[intl.locale].acf;

  const { yoast_head_json } = data[intl.locale];

  const modes = data[intl.locale].acf.fiscalBenefits.fiscalBenefits;
  const [selectedMode, setSelectedMode] = useState(
    modes[0].title.toUpperCase(),
  );

  const selectMode = useCallback((help) => {
    setSelectedMode(help);
  }, []);

  const renderDescriptionParagraph = (data) => {
    if (data.steps) {
      return (
        <div
          className="renderedDesc"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          dangerouslySetInnerHTML={{ __html: data.steps }}
        />

      );
    }
  };

  const modeTypes = useMemo(
    () => map(modes, (mod) => (
      <div
        className={
            mod.title.toUpperCase() === selectedMode.toUpperCase()
              ? 'azienda_singleHelpCategory_Selected'
              : 'azienda_singleHelpCategory'
          }
        data-selected-id={mod.title}
        selected={selectedMode === mod.title}
        style={{ marginRight: 20 }}
        onClick={() => selectMode(mod.title)}
      >
        {mod.title}
      </div>
    )),
    [modes, selectedMode],
  );

  const modeTypesBody = useMemo(
    () => map(modes, (mod) => (
      <div
        className="aziende_help_Body"
        style={{
          display:
              mod.title.toUpperCase() === selectedMode.toUpperCase()
                ? 'block'
                : 'none',
        }}
      >
        <div
          className="benefici_help_Body__data_desc"
          style={{
            backgroundColor: 'white',
            padding: 0,
            textAlign: 'justify',
          }}
        >
          {renderDescriptionParagraph(mod)}
        </div>
      </div>
    )),
    [modes, selectedMode],
  );

  useEffect(() => {
    const mylist = document.getElementById('benefici');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="benefici" className="benefici_main_container">
        {(foundationHero.imageBackgroundFoundationHero) && (
        <img
          id={foundationHero.positionFoundationHero}
          src={foundationHero.imageBackgroundFoundationHero}
          style={{
            width: '100%',
            height: '265px',
            objectFit: 'cover',
          }}
        />
        )}

        <div id={fiscalBenefits.positionFiscal} className="benefici_body" style={{ maxWidth: breakpoints.md && 330 }}>
          <h1 className="benefici_subtitle">{foundationHero.title}</h1>
          <div className="benefici_helpModes_container">{modeTypes}</div>
          {modeTypesBody}
          <br />
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '15px 0',
            }}
          >
            {modes[0].button && (
            <Button
              label={modes[0].button.toUpperCase()}
              style={{
                backgroundColor: '#D75F2B',
                color: 'white',
                cursor: 'pointer',
              }}
            />
            )}
          </div>
          <br />
        </div>

        <div id={howToSupport.positionSupport} style={{ width: breakpoints.md ? 330 : 790 }}>
          <div className="cantact_help_Body">
            <div
              className="aziende_help_Body__data_title"
            >
              {intl.formatMessage({ id: 'contact_us' })[0].toUpperCase()
                .concat(intl.formatMessage({ id: 'contact_us' })
                  .substring(1)
                  .toLowerCase())}
            </div>
            {supportMethods.support[0].contacts.map((supp) => {
              if (!supp.fieldLabel || !supp.fieldValue || !supp.filedButtonLabel) return null;
              return (
                <div className="benefici_help_Body__data_desc">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: !breakpoints.md ? 'space-evenly' : 'flex-start',
                      flexDirection: !breakpoints.md ? 'row' : 'column',
                    }}
                  >
                    <div style={{
                      margin: breakpoints.md && '10px 0',
                      marginRight: 15,
                      fontWeight: 'bold',
                      alignSelf: breakpoints.md && 'flex-start',
                    }}
                    >
                      {supp.fieldLabel}
                    </div>
                    <div
                      style={{
                        fontWeight: 300,
                        fontStyle: 'normal',
                        backgroundColor: 'white',
                        padding: 10,
                        fontSize: 15,
                        width: !breakpoints.md ? 340 : 270,
                        marginRight: !breakpoints.md ? 20 : 0,
                      }}
                    >
                      {supp.fieldValue}
                    </div>
                    <div
                      style={{
                        margin: breakpoints.md && '10px 0',
                        backgroundColor: '#003F6E',
                        color: 'white',
                        padding: '10px 0',
                        fontSize: 15,
                        width: intl.locale === 'es' ? 210 : 140,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        alignSelf: breakpoints.md && 'flex-start',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        borderRadius: 5,
                      }}
                    >
                      {supp.filedButtonLabel}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>
    </>
  );
}

export default Benefici;
